








import { Component, Vue } from "vue-property-decorator";
import RevisarReservaChapuzonKids2025 from "@/components/Reserva/RevisarReservaChapuzonKids2025.vue";

@Component({
  components: {
    RevisarReservaChapuzonKids2025
  }
})
export default class RevisarReservaChapuzonKids2025View extends Vue {}
